<template>
<div>
<v-navigation-drawer v-model="sidebar" app disable-resize-watcher height="100%">
  <v-list-item>
    <h1 class="logo">
      <router-link to="/my-events" tag="span" style="cursor: pointer">
        <v-img src="/images/Venu.png"></v-img>
      </router-link>
    </h1>
  </v-list-item>
  <v-spacer></v-spacer>
  <v-list-item class="search-box">
      <form @submit.prevent="searchSubmit">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          rounded
          dense
          outlined
          hide-details
          clear-icon="mdi-close-circle"
          clearable
          @click:clear="clearSearch"
          @keyup="searchSubmit()"
        ></v-text-field>
      </form>
      <v-card v-if="searchResult" class="search-results">
        <global-search-results userType="Artist" :searchResults="searchArtists" :loading="loading" />
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <global-search-results userType="Venue" :searchResults="searchVenues" :loading="loading" />
      </v-card>
  </v-list-item>
  <v-list>
    <v-list-item v-for="item in mobileMenuItems" :key="item.title" :to="item.path">
      <!-- <v-list-item-action> -->
        <!-- <v-icon>{{ item.icon }}</v-icon> -->
      <!-- </v-list-item-action> -->
      <v-list-item-content>{{ item.title }}</v-list-item-content>
    </v-list-item>
  </v-list>
  <template v-slot:append>
    <div class="pa-2">
      <v-btn block color="primary" rounded @click="logout">Logout</v-btn>
    </div>
  </template>
</v-navigation-drawer>

<v-app-bar app color="transparent" flat class="blur">
  <span class="hidden-md-and-up">
    <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
  </span>
  <!-- <div class="app-nav-content"> -->
  <!-- <v-toolbar flat short> -->
    <v-toolbar-title class="hidden-md-and-down">
      <h1 class="logo">
        <router-link to="/my-events" tag="span" style="cursor: pointer">
          <v-img src="/images/Venu.png"></v-img>
        </router-link>
      </h1>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="search-box hidden-xs-only">
      <form @submit.prevent="searchSubmit">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          rounded
          dense
          outlined
          hide-details
          clear-icon="mdi-close-circle"
          clearable
          @click:clear="clearSearch"
          @keyup="searchSubmit()"
        ></v-text-field>
      </form>
      <v-card v-if="searchResult" class="search-results">
        <global-search-results userType="Artist" :searchResults="searchArtists" :loading="loading" />
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <global-search-results userType="Venue" :searchResults="searchVenues" :loading="loading" />
      </v-card>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-xs-only">
      <v-btn text v-for="item in menuItems" :key="item.title" :to="item.path">
        <!-- <v-icon left dark>{{ item.icon }}</v-icon> -->
        {{ item.title }}
      </v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <messages-popup :userUnread="userUnread"></messages-popup>
    </v-toolbar-items>
    <v-spacer class="hidden-sm-and-down"></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-menu left bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            rounded
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
          
          <v-list dense>
            <template v-for="(item, index) in profileMenu">
              <v-list-item
                :key="item.title"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-btn block text rounded :to="item.path">{{ item.title }}</v-btn>

                  </v-list-item-title>

                </v-list-item-content>
              </v-list-item>
              <v-divider
                :key="index"
              ></v-divider>

            </template>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn block text rounded @click="logout">Sign out</v-btn>

                </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
          </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-spacer class="hidden-sm-and-down"></v-spacer>
    <v-btn color="primary" outlined rounded class="hidden-sm-and-down" @click.prevent="requestClick">
      {{ requestButtonType }}
    </v-btn>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <v-card>
        <v-form v-model="valid" ref="paymentForm" @submit.prevent="payment">
          <v-card-title>
            <span class="headline">Add money</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            You do not have enough funds, please top up your account
          </v-card-text>
          <v-card-text class="mt-4">
            <v-text-field
              v-model="amount"
              :rules="amountRules"
              rounded
              required
              outlined
              hide-details="auto"
            >
              <template v-slot:label>
                Amount<span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" rounded text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" rounded text type="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
</v-app-bar>
</div>
  
</template>

<script>
import GlobalSearchResults from './GlobalSearchResults'
import MessagesPopup from './messages/MessagesPopup'
  export default {
    components: {
      GlobalSearchResults,
      MessagesPopup
    },
    data() {
      return {
        user: auth.user,
        search: '',
        sidebar: false,
        profileMenu: [
          {
            title: 'Profile',
            path: '/profile'
          },
          {
            title: 'Settings',
            path: '/settings'
          },
          {
            title: 'Dashboard',
            path: '/dashboard'
          },
          {
            title: 'About Us',
            path: '/about'
          },
          {
            title: 'FAQ',
            path: '/faq'
          },
          {
            title: 'Help',
            path: '/help'
          }
        ],
        searchArtists: [],
        searchVenues: [],
        searchResult: false,
        loading: false,
        valid: false,
        amountRules: [
          v => !!v || 'This field is required',
          v => (v && /^[0-9]+$/g.test(v)) || 'Only numbers are allowed'
        ],
        dialog: false,
        amount: '',
      }
    },
    computed: {
      userType() {
          if(this.user.type === 'Artist') {
            return 'Venues'
          } else {
            return 'Artists'
          }
      },
      requestButtonType() {

        return (this.user.type === 'Artist' ? 'Request a Show' : 'Create Event');
      },
      userUnread() {
        return this.user.unread
      },
      
      mobileMenuItems() {
        return [
          {
            title: 'Profile',
            path: '/profile'
          },
          {
            title: 'Settings',
            path: '/settings'
          },
          {
            title: 'Dashboard',
            path: '/dashboard'
          },
          {
            title: 'Events',
            path: '/my-events'
          },
          {
            title: this.userType,
            path: '/search'
          }
        ]
      },
        menuItems() {
          return [
            {
              title: 'Dashboard',
              path: '/dashboard'
            },
            {
              title: 'Events',
              path: '/my-events'
            },
            {
              title: this.userType,
              path: '/search'
            }
          ]
        },
    },
    watch: {
      '$route': 'clearSearch'
    },
    methods: {
      logout() {
        auth.logout()
        // this.$router.push('/')
      },
      searchSubmit() {
        if(!this.search) {
          this.searchArtists = []
          this.searchVenues = []
          this.searchResult = false
          return
        }
        this.loading = true
        this.searchResult = true
        this.searchForArtists()
        this.searchForVenues()
      },
      searchForArtists() {
        // this.searchArtists = []
        // this.nothingFound = false
          // let temp = []
        axios.get(`/api/artists?name=${encodeURI('%' + this.search) + '%'}`)
          .then(({data}) => {
            // console.log(data.data)
            this.searchArtists = data.data
            this.loading = false
            if(!this.searchArtists.length) {
              // this.noAritstsFound = true
            }
            // temp = data.data
            // return data.data
          })
          .catch(({response}) => {
            if(response.status === 401) {
              this.logout()
            }
          })
      },
      searchForVenues() {
        // this.searchVenues = []
        // this.nothingFound = false
          // let temp = []
        axios.get(`/api/venues?name=${encodeURI('%' + this.search) + '%'}`)
          .then(({data}) => {
            // console.log(data.data)
            this.searchVenues = data.data
            this.loading = false
            // this.searchResult = true
            if(!this.searchVenues.length) {
              // this.noVenuesFound = true
            }
            // temp = data.data
            // return data.data
          })
          .catch(({response}) => {
            if(response.status === 401) {
              this.logout()
            }
          })
      },
      clearSearch() {
        this.loading = false
        this.searchResult = false
        this.search = ''
      },
      payment() {
        if(this.$refs.paymentForm.validate()) {
          axios.post('/api/payments', {'amount': this.amount})
            .then(({data}) => {
              window.location.href = data.data.action
            })
            .catch((response) => {
              console.log(response)
            })
        }
      },
      close() {
        this.dialog = false
      },
      requestClick() {
        if(this.user.balance >= 1) {
          this.$router.push('/events/create')
        } else {
          this.dialog = true
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.search-box {
  align-items: center;
  position: relative;
}
.app-nav-content {
  align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
}
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 300px;
  max-width: 100vw;
  @media screen and (max-width: 767px) {
    min-width: 200px;
    z-index: 10;
  }
}
</style>