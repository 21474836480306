// window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

const token = window.localStorage.getItem('token')
// if(!token) {
//   console.error('Bearer token not found.')
// }

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');
window.initEcho = (initToken) => {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.MIX_PUSHER_APP_KEY,
    //     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
        forceTLS: true,
        wsHost: window.location.hostname,
        wsPort: 80,
        wssPort: 443,
        disableStats: true,
        auth: {
            headers: {
                Authorization: 'Bearer ' + initToken
            },
        }
    });
}
if(token) {
    window.initEcho(token)
    console.log('new Echo created')
}
