<template>
  <!-- <main> -->
  <v-app :style="cssVars">
    <app-nav v-if="authenticated"></app-nav>
    <v-main>
      <v-container v-if="authenticated">
        <router-view :authenticated="authenticated"></router-view>
      </v-container>
      <!-- <v-container v-else fluid class="login-container"> -->
        <router-view v-else></router-view>
      <!-- </v-container> -->
    </v-main>
    <v-bottom-navigation
      :app="true"
      v-if="authenticated"
      class="d-sm-none blur"
      color="#0A84FF"
      background-color="transparent"
      flat
    >
      <v-btn to="/search" text retain-focus-on-click>
        <span>Discover</span>
        <v-icon>mdi-compass-outline</v-icon>
      </v-btn>

      <v-btn to="/events/create" color="orange" text>
        <span>Request a Show</span>
        <v-icon>mdi-calendar</v-icon>
      </v-btn>

      <v-btn to="/dashboard" text retain-focus-on-click>
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
  <!-- </main> -->
</template>

<script>
  import AppNav from './AppNav'
  export default {
    components: {
      AppNav
    },
    data() {
      return {
        // authenticated: auth.user.length ? true : false
        authenticated: auth.check()
      }
    },
    computed: {
      cssVars() {
        return {
          '--bgImage': this.bgImage()
        }
      }
    },
    methods: {
      appNavVisible() {
        // console.log(this.$route)
        // if(this.$route.name === 'login' || this.$route.name === 'register' || this.$route.path === '/faq' || this.$route.path === '/about') {
        if(!auth.user.length) {
          console.log(auth.user)
          return false
        } else {
          return true
        }
      },
      userLogout() {
        this.authenticated = false
        this.$router.push('/login')
      },
      userLogin() {
        // this.$router.replace('/login')
        console.log('login')
        this.authenticated = true
      },
      bgImage() {
        // console.log(this.$route)
        // if(this.$route.name)
        switch (this.$route.name) {
          case 'search':
          case 'dashboard':
            return 'url(/images/Discover_and_Search.webp)'
            // break;
          case 'userEvents':
          case 'createEvent':
          case 'singleEvent':
          case 'editEvent':
            return 'url(/images/Calendar_Events_List.webp)'
          case 'profile':
          case 'userProfile':
          case 'settings':
            return 'url(/images/iStock-171576655.webp)'
          default:
            break;
        }
      }
    },
    created() {
      EventBus.$on('userLoggedOut', this.userLogout)
      EventBus.$on('userLoggedIn', this.userLogin)
    }
  }
</script>

<style lang="scss" scoped>
main {
  height: 100%;
  width: 100%;
  overflow: auto;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
// .v-main__wrap {
//   max-height: 100%;
//   overflow: auto;
// }
.login-container {
  // height: 100%;
  display: flex;
}
</style>
<style lang="scss">

.v-main {
  
  &:before {
    background-image: var(--bgImage);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    content: '';
    filter: blur(8px);
    opacity: .6;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // z-index: 1;
  }
  &__wrap {
    z-index: 1;
  }
}
.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: #3f51b5 !important;
}
.no-hover-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent !important;
}
.v-dialog,
.v-card {
  .v-progress-circular--indeterminate:not(.v-progress-circular--visible) .v-progress-circular__overlay,
  .v-progress-circular--indeterminate:not(.v-progress-circular--visible)>svg {
    animation-play-state: running!important;
  }
}
</style>