<template>
  <v-list
    two-line
    class="pt-0 pb-0"
  >
    <template v-for="(user, index) in users">
      <!-- <v-subheader
        v-if="item.header"
        :key="item.header"
        v-text="item.header"
      ></v-subheader> -->

      <!-- <v-divider
        v-else-if="item.divider"
        :key="index"
        :inset="item.inset"
      ></v-divider> -->

      <v-list-item
        :key="user.name"
        @click="changeInterlocutor(user)"
        :class="{'v-list-item--active': user.id === value || writeNewMessage}"
      >

        <v-list-item-avatar left>
          <v-img :src="user.avatar ? `${$ATTACHMENT_URL}/${user.avatar}` : '/images/profile-pic.png'"></v-img>
        </v-list-item-avatar>
        <v-badge
          color="indigo"
          :content="user.unreaded"
          :value="user.unreaded > 0 ? user.unread : ''"
          overlap
          class="messages-list__badge"
        >
          <v-list-item-content>
            <v-list-item-title v-html="user.name"></v-list-item-title>
            <v-list-item-subtitle v-html="user.description"></v-list-item-subtitle>
          </v-list-item-content>
        </v-badge>

      </v-list-item>
      <v-divider v-if="index < users.length - 1" :key="index"></v-divider>
    </template>
  </v-list>
</template>

<script>
  export default {
    props: {
      users: {
        type: Array,
        default: () => []
      },
      activeFriend: {
        default: 0
      },
      writeNewMessage: {
        type: Boolean,
        default: false
      }
    },
    // data() {
    //   return {
    //     value: this.activeFriend ? this.activeFriend : 0
    //   }
    // },
    computed: {
      value() {
        return this.activeFriend ? this.activeFriend : 0
      }
    },
    methods: {
      changeInterlocutor(user) {
        if(this.writeNewMessage) {
          return
        }
        this.$emit('userListClick', user)
      }
    }
  }
</script>

<style lang="scss" scoped>
// .v-list-item {
//   &__content {
//     @media screen and (max-width: 767px) {
//       max-width: 60%;
//     }
//   }
// }
.messages-list__badge {
  align-items: center;
  align-self: center;
  display: inline-flex;
  flex-wrap: wrap;
  flex: 0 1;
  max-width: 80%;
}
</style>