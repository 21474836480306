<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    v-resize="onResize"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        rounded
        class="message-btn"
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          color="indigo"
          overlap
          :content="unread"
          :value="unread > 0 ? unread : ''"
        >
          <v-icon>mdi-message-text-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card class="messages-wrap">
        <v-toolbar>
          <v-btn v-if="writeNewMessage" icon @click="writeNewMessageBackBtn">
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-btn>
          <v-toolbar-title>Messages</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="!writeNewMessage" color="primary" rounded @click="newMessageHandler">New Message</v-btn>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-if="mobileScreen && privateDialog && !writeNewMessage" class="mt-3 px-4">
          <v-menu
            bottom
            offset-y
            origin="top center"
            transition="slide-y-transition"
            max-height="50%"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-btn
                  icon
                  @click="backTolist"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-img 
                  class="rounded-circle mx-4" 
                  max-width="40" 
                  max-height="40" 
                  :src="activeUserData.avatar ? `${$ATTACHMENT_URL}/${activeUserData.avatar}` : '/images/profile-pic.png'"
                ></v-img>
                {{ activeUserData.name }}
              </div>
            </template>
            <messages-list @userListClick="userListClick($event)" :users="users" :activeFriend="activeFriend"></messages-list>
          </v-menu>
        </div>
        <v-row class="py-3 pa-4 messages-container" justify="center" no-gutters>
          <v-scale-transition origin="top center">
            <v-col v-if="!writeNewMessage && !mobileScreen" class="messages-col test" cols="12" md="4">
              <v-card class="messages-list">
                <messages-list @userListClick="userListClick($event)" :users="users" :activeFriend="activeFriend"></messages-list>
              </v-card>
            </v-col>
          </v-scale-transition>
          <v-scale-transition origin="top center">
            <v-col v-if="!writeNewMessage && !privateDialog && mobileScreen" class="messages-col test" cols="12" md="4">
              <v-card class="messages-list">
                <messages-list @userListClick="userListClick($event)" :users="users" :activeFriend="activeFriend"></messages-list>
              </v-card>
            </v-col>
          </v-scale-transition>
          <!-- <v-slide-x-transition>
            <v-col v-if="privateDialog && !mobileScreen && !writeNewMessage" class="messages-col test" cols="12" md="4">
              <v-card class="messages-list">
                <messages-list @userListClick="userListClick($event)" :users="users" :activeFriend="activeFriend"></messages-list>
              </v-card>
            </v-col>
          </v-slide-x-transition> -->
          <v-scale-transition origin="top left">
            <v-col v-if="privateDialog && !writeNewMessage" class="messages-col" cols="12" md="8">
              <private-message @sendMessage="sendMessage($event)" :allMessages="allMessages" :loadingMessages="loadingMessages"></private-message>
            </v-col>
          </v-scale-transition>
          <v-scale-transition origin="top center">
            <v-row v-if="writeNewMessage" class="new-message-wrap" no-gutters>
              <v-col class="messages-col" cols="12" md="4">
                <div class="search-box">
                  <form @submit.prevent="searchSubmit">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      rounded
                      dense
                      outlined
                      hide-details
                      clear-icon="mdi-close-circle"
                      clearable
                      @click:clear="clearSearch"
                      @keyup="searchSubmit()"
                    ></v-text-field>
                  </form>
                  <v-card v-if="searchResult" class="search-results">
                    <global-search-results userType="Artist" :searchResults="searchArtists" :loading="loading" :inviteArtist="true" @artistSelected="friendSelected" />
                    <v-divider></v-divider>
                    <v-divider></v-divider>
                    <v-divider></v-divider>
                    <global-search-results userType="Venue" :searchResults="searchVenues" :loading="loading" :inviteArtist="true" @artistSelected="friendSelected" />
                  </v-card>
                </div>
                <messages-list class="mt-3" v-if="writeTo.length" :writeNewMessage="writeNewMessage" @userListClick="userListClick($event)" :users="writeTo" :activeFriend="activeFriend"></messages-list>
              </v-col>
              <v-col class="messages-col" cols="12" md="8">
                <private-message @sendMessage="sendMessage($event)" :allMessages="allMessages" :loadingMessages="loadingMessages"></private-message>
              </v-col>
            </v-row>
          </v-scale-transition>
        </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import MessagesList from './MessagesList'
import PrivateMessage from './PrivateMessage'
import GlobalSearchResults from '../GlobalSearchResults'
  export default {
    components: {
      MessagesList,
      PrivateMessage,
      GlobalSearchResults
    },
    props: {
      userUnread: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        dialog: false,
        privateDialog: false,
        users: [],
        allMessages: [],
        activeFriend: null,
        typingFriend: {},
        typingClock: null,
        unread: this.userUnread,
        message: '',
        loadingMessages: false,
        writeNewMessage: false,
        search: '',
        searchArtists: [],
        searchVenues: [],
        searchResult: false,
        loading: false,
        writeTo: [],
        savedActiveFriend: 0,
        mobileScreen: false,
        activeUser: false,
      }
    },
    computed: {
      user() {
        return auth.user
      },
      activeUserData() {
        return this.users.filter(user => user.id == this.activeFriend)[0]
      }
    },
    created(){
      this.fetchUsers();
      Echo.private('privatechat.' + this.user.id)
        .listen('PrivateMessageSent',(e) => {
          if (this.activeFriend === e.message.user_id) {
            this.allMessages.push(e.message)
          }
          this.unread++
          this.fetchUsers()
          // setTimeout(this.scrollToEnd,100);

      })
      .listenForWhisper('typing', (e) => {
        if(e.user.id==this.activeFriend){
          this.typingFriend=e.user;
          if(this.typingClock) clearTimeout();
          this.typingClock=setTimeout(() => {
            this.typingFriend={};
          },9000);
        }
      });
      EventBus.$on('unreadUpdated', this.unreadUpdated)
      EventBus.$on('newMessageTo', this.newMessageTo)
    },
    watch: {
      '$route': 'clearSearch',
      dialog() {
        if(this.dialog === false) {
          this.activeFriend = null
          this.privateDialog = false
        }
      }
    },
    methods: {
      backTolist() {
        this.privateDialog = false
        this.activeFriend = null
      },
      userListClick(user) {
        this.privateDialog = true
        this.activeFriend = user.id
        this.fetchMessages()
      },
      fetchUsers() {
        axios.get('/api/messages')
          .then(({data}) => {
            this.users = data.data.filter(e => e.id !== this.user.id)
            this.users.sort((a, b) => {
              if (a.last_created < b.last_created) {
                return 1;
              } else if (a.last_created > b.last_created) {
                return -1;
              } else {
                return 0;
              }
            })
            // this.unread = data.data.filter(e => e.id === this.user.id)[0].unread
          })
      },
      sendMessage(text) {
        if(!text) {
          console.log('Please enter message')
          return
        }
        if(!this.activeFriend) {
          console.log('Select friend')
          return
        }
        this.message = text
        axios.post('/api/messages', {message: this.message, receiver_id:this.activeFriend})
        // axios.post('/api/messages', {message: this.message, receiver_id:4})
          .then(response => {
            this.message=null;
            this.allMessages.push(response.data.data)
            setTimeout(this.scrollToEnd,100);
          });
      },
      unreadUpdated(number) {
        this.unread -= number
        this.fetchUsers()
      },
      fetchMessages() {
        if(!this.activeFriend){
          return alert('Please select friend');
        }
        this.loadingMessages = true
        this.receiver = this.users.find(user => user.id === this.activeFriend)
        axios.get('/api/messages/'+this.activeFriend)
          .then(response => {
            this.allMessages = response.data.data
            this.loadingMessages = false
            setTimeout(this.scrollToEnd,100);
          });
      },
      newMessageHandler() {
        this.writeNewMessage = true
        this.allMessages = []
        this.savedActiveFriend = this.activeFriend
        this.activeFriend = null
      },
      logout() {
        auth.logout()
      },
      searchSubmit() {
        if(!this.search) {
          this.searchArtists = []
          this.searchVenues = []
          this.searchResult = false
          return
        }
        this.loading = true
        this.searchResult = true
        this.searchForArtists()
        this.searchForVenues()
      },
      searchForArtists() {
        axios.get(`/api/artists?name=${encodeURI('%' + this.search) + '%'}`)
          .then(({data}) => {
            this.searchArtists = data.data
            this.loading = false
          })
          .catch(({response}) => {
            if(response.status === 401) {
              this.logout()
            }
          })
      },
      searchForVenues() {
        axios.get(`/api/venues?name=${encodeURI('%' + this.search) + '%'}`)
          .then(({data}) => {
            this.searchVenues = data.data
            this.loading = false
          })
          .catch(({response}) => {
            if(response.status === 401) {
              this.logout()
            }
          })
      },
      clearSearch() {
        this.loading = false
        this.searchResult = false
        this.search = ''
      },
      friendSelected(user) {
        this.activeFriend = user.user_id
        this.writeTo[0] = user
        this.writeTo[0].avatar = user.attachments.filter((el) => el.type === 'Avatar').length ? user.attachments.filter((el) => el.type === 'Avatar')[0].attachment : ''
        this.searchResult = false
        // this.privateDialog = true
        this.fetchMessages()
      },
      writeNewMessageBackBtn() {
        this.writeNewMessage = false
        this.activeFriend = this.savedActiveFriend
        this.search = ''
        this.writeTo = []
        this.fetchUsers()
        if(this.activeFriend) {
          this.fetchMessages()
        }
      },
      scrollToEnd(){
        document.getElementById('message-box').scrollTo(0,99999);
      },
      onResize() {
        window.innerWidth < 1024 ? this.mobileScreen = true : this.mobileScreen = false
      },
      newMessageTo(user) {
        this.dialog = true
        this.newMessageHandler()
        this.friendSelected(user)
      }
    }
  }
</script>

<style lang="scss" scoped>
.messages-wrap {
  height: 100%;
}
.messages-container {
  height: calc(100% - 64px);
  @media screen and (max-width: 1024px) {
    height: calc(100% - 120px);
  }
  // overflow-y: auto;
}
.messages-col {
  max-height: 100%;
}
.messages-list {
  overflow: auto;
  max-height: 100%;
}
.new-message-wrap {
  max-width: 100%;
  max-height: 100%;
}
</style>