<template>
  <v-card color="transparent" class="private-chat d-flex flex-column">
    <div v-if="!loadingMessages" id="message-box" class="private-chat__body">
      <div class="private-chat__inner">
        <div 
          v-for="message in allMessages"
          :key="message.id"
          :class="{'align-end': message.receiver_id !== userId, 'align-start': message.receiver_id === userId}"
          class="d-flex flex-column justify-start mb-2"
        >
          <v-card
            max-width="75%"
            rounded="xl"
            :color="message.receiver_id !== userId ? 'primary' : 'secondary'"
            class="message"
          >
            <div class="p-2">
              {{ message.message }}
            </div>
          </v-card>
          <div class="caption pl-2">
            <span>{{ formatDate(message.created_at) }}</span>
          </div>
        </div>
      </div>
    </div>
    <v-card v-if="!loadingMessages" class="private-chat__message message-box" flat>
      <v-form @submit.prevent="sendMessage">
        <v-row no-gutters>
          <v-col class=" flex-grow-1 flex-shrink-1">
            <v-text-field
              v-model="messageInput"
              no-resize
              rounded
              outlined
              required
              rows="2"
              hide-details
              placeholder="Message..."
            ></v-text-field>
          </v-col>
          <v-col class="d-flex justify-end align-center flex-grow-0 flex-shrink-0 ml-4">
            <v-btn
              icon
              color="primary"
              type="submit"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    
    <v-card v-if="loadingMessages" flat class="d-flex align-center private-chat__loader" color="transparent">
      <v-card-text class="d-flex justify-center">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>

      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
  export default {
    props: {
      allMessages: {
        type: Array,
        default: () => []
      },
      loadingMessages: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        messageInput: '',
        userId: auth.user.id
      }
    },
    watch: {
      'allMessages': 'updateUnreaded'
    },
    methods: {
      sendMessage() {
        this.$emit('sendMessage', this.messageInput)
        this.messageInput = ''
      },
      updateUnreaded() {
        this.readMessages(this.allMessages).then(
          result => {
            console.log('result ' + result)
            EventBus.$emit('unreadUpdated', result)
          },
          error => {
            console.log(error)
          }
        )
      },
      formatDate(date) {
        const formatter = new Intl.DateTimeFormat('en-US', {
          month: 'numeric',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
        return formatter.format(Date.parse(date))
      },
      readMessages(messages) {
        return new Promise((resolve, reject) => {
          let readedMessages = 0
          const time = (new Date()).toISOString()
          const unreadMessages = messages.filter(el => !el.read_at && el.receiver_id === this.userId)
          console.log(unreadMessages)
          if(unreadMessages && unreadMessages.length) {
            unreadMessages.map(message => {
                message.read_at = time
                axios.patch(`/api/messages/${message.id}`, message)
                  .then(({data}) => {
                    readedMessages++
                    if(readedMessages === unreadMessages.length) {
                      resolve(readedMessages)
                    }
                  })
                  .catch(error => {
                    reject(error)
                  })
            })
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.private-chat {
  width: 100%;
  height: 100%;
  &__body {
    flex: 1 1 auto;
    max-height: calc(100% - 86px);
    overflow: auto;
    padding: 16px;
  }
  &__inner {
    min-height: 100%;
  }
  &__message {
    flex: 0 0 auto;
    padding: 15px;
  }
  &__loader {
    height: 100%;
  }
}
.message {
  padding: 10px;
}

</style>