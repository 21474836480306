<template>
  <div>
    <v-card-subtitle class="text-center justify-center">
      {{userType}}
      <v-divider></v-divider>
    </v-card-subtitle>
    <div v-if="loading">
      <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          ></v-skeleton-loader>
    </div>
    <div v-else>
      <v-card v-if="searchResults.length && inviteArtist" color="transparent" flat>
        <v-list-item v-for="(item, key) in searchResults" :key="key" @click="selectArtist(item)">
          <v-list-item-avatar>
            <v-img v-if="avatar(item).length" :src="`${$ATTACHMENT_URL}/${avatar(item)[0].attachment}`"></v-img>
            <v-img v-else src="/images/profile-pic.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
            <v-list-item-subtitle>
              <!-- <span>{{userType}}</span>
              <v-divider vertical></v-divider> -->
              <span v-if="item.street && userType === 'Venue'">{{item.street}}, </span>
              <span v-if="item.city">{{item.city}}<span v-if="userType === 'Venue'">, </span></span>
              <span v-if="item.state && userType === 'Venue'">{{item.state}}, </span>
              <span v-if="item.country && userType === 'Venue'">{{item.country}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-card v-else-if="searchResults.length" color="transparent" flat>
        <v-list-item v-for="(item, key) in searchResults" :key="key" :to="`/profiles/${userType.toLowerCase()}s/${item.id}`">
          <v-list-item-avatar>
            <v-img v-if="avatar(item).length" :src="`${$ATTACHMENT_URL}/${avatar(item)[0].attachment}`"></v-img>
            <v-img v-else src="/images/profile-pic.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
            <v-list-item-subtitle>
              <!-- <span>{{userType}}</span>
              <v-divider vertical></v-divider> -->
              <span v-if="item.street && userType === 'Venue'">{{item.street}}, </span>
              <span v-if="item.city">{{item.city}}<span v-if="userType === 'Venue'">, </span></span>
              <span v-if="item.state && userType === 'Venue'">{{item.state}}, </span>
              <span v-if="item.country && userType === 'Venue'">{{item.country}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-card-text v-else color="transparent" flat>No matches found</v-card-text>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      searchResults: {
        type: Array,
        default: []
      },
      userType: {
        type: String,
        default: ''
      },
      loading: {
        type: Boolean,
        default: false
      },
      inviteArtist: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      avatar(item) {
        return item.attachments.filter((el) => el.type === 'Avatar')
      },
      selectArtist(item) {
        this.$emit('artistSelected', item)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>