require('./bootstrap');

window.Vue = require('vue');

import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import Routes from './router';
import App from './components/App';
import Auth from './auth';
import vuetify from './plugins/vuetify';
// import 'leaflet/dist/leaflet.css';
// import './plugins/apexcharts'
// import {store} from './store'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

window.auth = new Auth();
window.Vue.use(VueRouter);


window.EventBus = new Vue;

window.to24Hours = function(date) {
  let sanitized = date.replace(/\s(\d+)(:\d{2})\s[AP]M$/, (s,p1,p2) => {
    return 'T' + (p1.length == 2 ? p1 : '0' + p1) + p2
  })
  let result = new Date(sanitized)
  if (date.includes(' PM')) {
    result.setTime(result.getTime() + 12 * 60 * 60 * 1000)
  }
  return result
}

Vue.prototype.$ATTACHMENT_URL = 'https://venu-storage.s3.us-east-1.amazonaws.com'

const app = new Vue({
  el: '#app',
  vuetify,
  router: Routes,
  // store,
  render: h => h(App),
});