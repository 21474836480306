import VueRouter from 'vue-router';
// import Login from './pages/Login';
// import Dashboard from './pages/Dashboard';
// import Profile from './pages/Profile';
// import UserProfile from './pages/UserProfile';
// import SignUp from './pages/SignUp';
// import Search from './pages/Search';
// import Faq from './pages/Faq';
// import About from './pages/About';
// import UserEvents from './pages/UserEvents';
// import Settings from './pages/Settings';
// import CreateEvent from './pages/CreateEvent'
// import SingleEvent from './pages/SingleEvent'
// import Payment from './pages/Payment'

const Login = () => import(/* webpackChunkName: "login" */'./pages/Login.vue')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */'./pages/Dashboard.vue')
const Profile = () => import(/* webpackChunkName: "profile" */'./pages/Profile.vue')
const UserProfile = () => import(/* webpackChunkName: "userProfile" */'./pages/UserProfile.vue')
const SignUp = () => import(/* webpackChunkName: "signup" */'./pages/SignUp.vue')
const Search = () => import(/* webpackChunkName: "search" */'./pages/Search.vue')
const Faq = () => import(/* webpackChunkName: "faq" */'./pages/Faq.vue')
const About = () => import(/* webpackChunkName: "about" */'./pages/About.vue')
const UserEvents = () => import(/* webpackChunkName: "userEvents" */'./pages/UserEvents.vue')
const Settings = () => import(/* webpackChunkName: "settings" */'./pages/Settings.vue')
const CreateEvent = () => import(/* webpackChunkName: "createEvent" */'./pages/CreateEvent.vue')
const SingleEvent = () => import(/* webpackChunkName: "singleEvent" */'./pages/SingleEvent.vue')
const EditEvent = () => import(/* webpackChunkName: "editEvent" */'./pages/EditEvent.vue')
const Payment = () => import(/* webpackChunkName: "payment" */'./pages/Payment.vue')
const Unsubscribe = () => import(/* webpackChunkName: "unsubscribe" */'./pages/Unsubscribe.vue')
const Reset = () => import(/* webpackChunkName: "reset" */'./pages/Reset.vue')
const Verification = () => import(/* webpackChunkName: "verification" */'./pages/Verification.vue')
const Privacy = () => import(/* webpackChunkName: "privacy" */'./pages/Privacy.vue')
const Terms = () => import(/* webpackChunkName: "terms" */'./pages/Terms.vue')
const Cookies = () => import(/* webpackChunkName: "cookies" */'./pages/Cookies.vue')

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/search'
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: { middlewareAuth: true }
    },
    {
      path: '/my-events',
      name: 'userEvents',
      component: UserEvents,
      meta: { middlewareAuth: true }
    },
    {
      path: '/events/create',
      name: 'createEvent',
      component: CreateEvent,
      meta: {middlewareAuth: true}
    },
    {
      path: '/events/:id',
      name: 'singleEvent',
      component: SingleEvent,
      meta: {middlewareAuth: true}
    },
    {
      path: '/events/:id/edit',
      name: 'editEvent',
      component: EditEvent,
      meta: {middlewareAuth: true}
    },
    {
      path: '/profiles/:slug/:id',
      name: 'profile',
      component: Profile,
      meta: { middlewareAuth: true }
    },
    {
      path: '/profile',
      name: 'userProfile',
      component: UserProfile,
      meta: { middlewareAuth: true }
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: { middlewareAuth: true }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { middlewareAuth: true }
    },
    {
      path: '/payment/:id/:slug',
      name: 'payment',
      component: Payment,
      meta: { middlewareAuth: true }
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      name: 'register',
      path: '/register',
      component: SignUp
    },
    {
      name: 'unsubscribe',
      path: '/unsubscribe/:id/:token',
      component: Unsubscribe
    },
    {
      name: 'reset',
      path: '/reset/:id/:token',
      component: Reset
    },
    {
      name: 'verification',
      path: '/verification/:id/:token',
      component: Verification
    },
    {
      name: 'privacy',
      path: '/privacy-policy',
      component: Privacy
    },
    {
      name: 'terms',
      path: '/terms-of-service',
      component: Terms
    },
    {
      name: 'cookies',
      path: '/cookies-policy',
      component: Cookies
    }
  ],
  mode: 'history'
});

router.beforeEach((to, from, next) => {
    const main = document.querySelector('.v-main__wrap')
    if (main) {
      main.scrollTo(0, 0)
    }
    if (to.matched.some(record => record.meta.middlewareAuth)) {
        if (!auth.check()) {
            next({
                path: '/login',
                // query: { redirect: to.fullPath }
            });

            return;
        }
    }
    else if (to.matched.some(record => record.path === '/login') && auth.check()) {
      next({
        path: '/search'
      })

      return;
    }

    next();
})

export default router;