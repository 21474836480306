// import jwt_decode from 'jwt-decode';
class Auth {
  constructor() {
    // this.token = null;
    this.token = window.localStorage.getItem('token')
    const userData = window.localStorage.getItem('user')
    this.user = userData ? JSON.parse(userData) : null
    if(this.token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token
      this.getUser()
    }
  }
  async login(token, remember) {
    if(remember) {
      window.localStorage.setItem('token', token)
    }
    const userId = this.parseJwt(token).sub

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    
    await axios.get(`/api/users/${userId}`)
      .then(({data}) => {
        window.localStorage.removeItem('user')
        if(remember) {
          window.localStorage.setItem('user', JSON.stringify(data.data));
        }
        this.user = data.data
        if(!window.Echo) {
          // window.Echo = new Echo({
          //     broadcaster: 'pusher',
          //     key: process.env.MIX_PUSHER_APP_KEY,
          // //     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
          //     forceTLS: false,
          //     wsHost: window.location.hostname,
          //     // httpHost set to local
          //     wsPort: 6001,
          //     disableStats: true,
          //     auth: {
          //         headers: {
          //             Authorization: 'Bearer ' + token
          //         },
          //     }
          // });
          window.initEcho(token)
          console.log('new Echo on login')
        } else {
          window.Echo.options.auth.headers.Authorization = 'Bearer ' + token
          console.log(window.Echo.options)
          window.Echo.connect()
          console.log('Echo reconnect')
        }
        // console.log(data)
      })
      .catch(({response}) => {
        if(response.status === 401) {
          this.logout()
        }
      })

    this.token = token
    // console.log(this.parseJwt(token))

    EventBus.$emit('userLoggedIn')
  }
  check() {
    return !! this.token
  }
  logout() {
    this.token = null
    this.user = null
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('user')
    window.Echo.disconnect()
    delete axios.defaults.headers.common['Authorization']
    EventBus.$emit('userLoggedOut')
  }
  parseJwt (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = JSON.parse(decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('')))
    // console.log('jsonPayload')
    // console.log(jsonPayload)
    return jsonPayload

    // return JSON.parse(jsonPayload)
  }
  getUser() {
    const userId = this.parseJwt(this.token).sub
    axios.get(`/api/users/${userId}`)
      .then(({data}) => {
        this.user = data.data
        window.localStorage.removeItem('user')
        window.localStorage.setItem('user', JSON.stringify(data.data));
        console.log(this.user)
        // window.localStorage.setItem('user', JSON.stringify(data.data));
        EventBus.$emit('userLoggedIn')
      })
      .catch(({response}) => {
        if(response.status === 401) {
          this.logout()
        }
      })
  }
}

export default Auth;